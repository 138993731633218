import React, { useState, useEffect } from "react"
import Layout from "../../templates/Layout"
import Seo from "../../components/seo"
import styled, { keyframes } from "styled-components"
import textBottomGrey from "../../assets/text-bottom-grey.svg"
import textBottomGreyMobile from "../../assets/text-bottom-grey-mobile.svg"
import Paragraph from "../../components/typography/Paragraph"
import HeadingH2 from "../../components/typography/HeadingH2"
import signatureSrc from "../../assets/signature.png"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ReactVivus from "react-vivus"
import Rotate from "react-reveal/Rotate"
import Swing from "react-reveal/Swing"
import Fade from "react-reveal/Fade"
import Menu from "../../components/menu/Menu"
import Pattern from "../../assets/Vector.svg"
import { useSelector, useDispatch } from "react-redux"
import {
  setActivePatternMenu,
  setActivePage,
  setActiveLanguage,
} from "../../store/menuSlice"

const visibility = keyframes`
  from {opacity:0;}
  to {opacity:1}
`

const StyledBackgroundWrapper = styled.div`
  min-height: -webkit-fill-available;
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* animation: ${visibility} 1s linear both; */
  @media (max-width: 1023px) {
    height: auto;
  }
  @media (max-width: 810px) {
    height: 100vh;
  }
  @media (max-width: 500px) {
    height: auto;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    /* position: relative; */
  }
`

const StyledContentWrapper = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0 10%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
  }
  @media (max-width: 500px) {
    height: auto;
    justify-content: flex-start;
    margin-top: 100px;
    padding: 0;
  }
`

const StyledPhotoWrapper = styled.div`
  width: 45%;
  height: auto;
  padding-right: 50px;
  @media (max-width: 1443px) {
    padding-right: 0px;
  }
  @media (max-width: 1024px) {
    width: 70%;
    padding-right: 0px;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
`

const StyledCopyWrapper = styled.div`
  width: 55%;
  height: 100%;
  text-align: right;
  padding-right: 150px;
  padding-top: 7vh;
  position: relative;
  z-index: 2;
  @media (max-width: 2000px) {
    padding-top: 0vh;
    bottom: 4%;
  }
  @media (max-width: 1450px) {
    padding-top: 4vh;
    bottom: 0%;
  }
  @media (max-width: 1300px) {
    padding-top: 0vh;
  }
  @media (max-width: 1024px) {
    width: 100%;
    padding-right: 0px;
    padding-top: 12vh;
  }
  @media (max-width: 1023px) {
    padding-bottom: 5vh;
  }
  @media (max-width: 810px) {
    padding-top: 16vh;
  }
  @media (max-width: 500px) {
    padding-bottom: 0vh;
    padding: 0 10%;
    margin-bottom: 120px;
    bottom: 0%;
  }
`

const StyledTextBottom = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0px;
  left: 0;
  margin: 0;
  right: 0;
  display: block;
  pointer-events: none;
  @media (max-width: 500px) {
    display: none;
  }
`

const StyledTextBottomMobile = styled.img`
  pointer-events: none;
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0px;
  left: 0;
  margin: 0;
  right: 0;
  display: none;
  @media (max-width: 500px) {
    display: block;
    position: fixed;
  }
`

const rotate = keyframes`
  from {
    transform: rotate(-90deg);
opacity:0;
  }

  to {
    transform: rotate(0deg);
opacity:1
  }
  
`

const StyledRectangle = styled.div`
  width: 500px;
  height: 500px;
  position: absolute;
  border: 2px solid ${({ theme }) => theme.colors.green};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
  margin: auto;

  &.solid {
    transform: rotate(0deg);
    opacity: 1;
  }
  &.animation {
    animation: ${rotate} 0.9s ease-in-out both;
  }
  @media (max-width: 1443px) {
    width: 400px;
    height: 400px;
  }
  @media (max-width: 1024px) {
    width: 500px;
    height: 500px;
  }
  @media (max-width: 850px) {
    width: 400px;
    height: 400px;
  }
  @media (max-width: 426px) {
    width: 280px;
    height: 280px;
  }
`

const StyledGatsbyImage = styled.div`
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  width: 100%;
  z-index: 1;
  transition: filter 0.5s;

  /* &:hover {
    filter: invert(1);
  } */
  /* .gatsby-image-wrapper {
    height: 100%;
  } */
`

const signature = keyframes`
  from {
opacity:0;
  }

  to {
    opacity:1;
  }
`

const StyledSignature = styled.img`
  width: 162px;
  height: auto;
  position: absolute;
  right: 150px;
  animation: ${signature} 0.6s 0.2s ease-in-out both;
  @media (max-width: 1443px) {
    width: 116px;
  }
  @media (max-width: 1024px) {
    right: 0px;
    width: 162px;
  }
  @media (max-width: 850px) {
    width: 100px;
  }
  @media (max-width: 426px) {
    width: 90px;
  }
`

const pattern = keyframes`
  from {
      width:1vw;
      height:auto;
      opacity:1;
    }
  to {
    width:100vw;
    height:auto;
    opacity:0.03;
  }
`

const StyledPattern = styled.img`
  width: 500px;
  height: 500px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
  margin: auto;
  &.animation {
    animation: ${pattern} 0.5s linear both;
  }
  &.solid {
    width: 100vw;
    height: auto;
    opacity: 0.03;
  }
`

const PageName = styled.p`
  font-family: "Lato";
  font-size: 14px;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  transition: transform 0.3s ease-in-out;
  position: fixed;
  color: black;
  top: 51px;
  right: 100px;
  z-index: 11;
  display: none;
  @media (max-width: 1030px) {
    display: block;
  }
`

const About = ({ data, location }) => {
  const fromHome = location?.state?.fromHome || ""
  const menuData = data?.datoCmsMenu || ""
  const moduleData = data?.datoCmsAbout || ""

  const headline = moduleData?.headline || ""
  const description = moduleData?.description || ""
  const background = moduleData?.backgroundphoto?.url || ""
  const photo = moduleData?.mainphoto?.fluid || ""
  const photohover = moduleData?.mainphotohover?.fluid || ""
  const pageName = moduleData?.pagename || ""
  const pattern = moduleData?.backgroundpattern?.url || ""
  const menuPattern = moduleData?.menupattern?.url || ""
  const globalData = data?.datoCmsGlobal || ""

  const [image, setImage] = useState(photo)

  const dispatch = useDispatch()
  dispatch(setActivePage("about"))
  dispatch(setActiveLanguage("es"))
  dispatch(setActivePatternMenu(menuPattern))

  return (
    <Layout>
      <Seo title={pageName} />
      <PageName>{pageName}</PageName>
      <StyledPattern
        className={fromHome ? "animation" : "solid"}
        src={pattern}
      />

      <Menu data={menuData} globalData={globalData} />
      <StyledBackgroundWrapper>
        <StyledRectangle className={fromHome ? "solid" : "animation"} />

        <StyledContentWrapper>
          <StyledCopyWrapper>
            <HeadingH2 className="name about">{headline}</HeadingH2>

            <Paragraph className="ml-20">{description}</Paragraph>

            <StyledSignature src={signatureSrc} />
          </StyledCopyWrapper>
          <StyledPhotoWrapper>
            <StyledGatsbyImage
              onMouseOver={() => setImage(photohover)}
              onMouseOut={() => setImage(photo)}
            >
              <Img fluid={image} />
            </StyledGatsbyImage>
          </StyledPhotoWrapper>
        </StyledContentWrapper>

        <StyledTextBottom src={textBottomGrey} />

        <StyledTextBottomMobile src={textBottomGreyMobile} />
      </StyledBackgroundWrapper>
    </Layout>
  )
}

export default About

export const query = graphql`
  query aboutQueryEs {
    datoCmsGlobal(locale: { eq: "es" }) {
      languages
      socialmedia {
        name
        link
      }
      logo {
        url
      }
    }
    datoCmsAbout(locale: { eq: "es" }) {
      headline
      description
      pagename
      backgroundpattern {
        url
      }

      menupattern {
        url
      }
      mainphoto {
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
      mainphotohover {
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    datoCmsMenu(locale: { eq: "es" }) {
      _allMenuitemLocales {
        locale
      }
      menuitem {
        pagelink {
          __typename
          ... on DatoCmsContact {
            pagename
            menupattern {
              url
            }
          }
          ... on DatoCmsWork {
            pagename
            backgroundpattern {
              url
            }
            menupattern {
              url
            }
          }
          ... on DatoCmsHomepage {
            id
          }
          ... on DatoCmsAbout {
            pagename
            backgroundpattern {
              url
            }
            menupattern {
              url
            }
          }
        }
      }
    }
  }
`
